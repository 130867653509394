var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "checkOrder" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: "订单详情",
            top: "5vh",
            visible: _vm.isshowDialog,
            fullscreen: "",
            width: "1160px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isshowDialog = $event
            },
            close: _vm.close,
          },
        },
        [
          _vm.showForm
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "wrapper2",
                },
                [
                  _c(
                    "el-alert",
                    {
                      staticStyle: {
                        width: "86%",
                        "margin-top": "-70px",
                        "margin-left": "80px",
                      },
                      attrs: {
                        closable: false,
                        title: _vm.titleTip,
                        type: "success",
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.form.bill_type_text))])]
                  ),
                  _c("order-status", { attrs: { status: _vm.orderStatusStr } }),
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        "label-width": "80px",
                        inline: true,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "购货方", prop: "cust_name" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.cust_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "cust_name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.cust_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系人", prop: "boss" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.boss,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "boss",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.boss",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "dz",
                          attrs: { label: "地址", prop: "address" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "380px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.address,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "address",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.address",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "出货仓库", prop: "chck" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.chck,
                              callback: function ($$v) {
                                _vm.chck =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "chck",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "送货人", prop: "deliver_name" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.deliver_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "deliver_name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.deliver_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务员", prop: "staff_name" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                clearable: "",
                                placeholder: "",
                                disabled: _vm.form.ckzx_falg != "1" && _vm.edit,
                              },
                              model: {
                                value: _vm.form.staff_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "staff_id", $$v)
                                },
                                expression: "form.staff_id",
                              },
                            },
                            _vm._l(_vm.staffList, function (i, idx) {
                              return _c("el-option", {
                                key: idx,
                                attrs: { value: i.id, label: i.name },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "调拨单", prop: "allot_code" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.allot_code,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "allot_code",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.allot_code",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "派单", prop: "delivery_time" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.delivery_time,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "delivery_time",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.delivery_time",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "到货", prop: "arrive_time" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.arrive_time,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "arrive_time",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.arrive_time",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      ref: "middleTable",
                      attrs: {
                        stripe: "",
                        data: _vm.list,
                        height: "360px",
                        "row-class-name": _vm.tableRowClassName,
                      },
                      on: {
                        "selection-change": _vm.setSelectRows,
                        "cell-click": _vm.handlercellclick,
                      },
                    },
                    [
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            align: "center",
                            type: "index",
                            width: "50",
                          },
                        },
                        [_c("template", { slot: "header" })],
                        2
                      ),
                      _c("el-table-column", {
                        attrs: {
                          prop: "type",
                          align: "center",
                          label: "类型",
                          "min-width": "120px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.index === _vm.tabClickIndex &&
                                  _vm.tabClickLabel === "类型"
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100px" },
                                              attrs: {
                                                placeholder: "选择类型",
                                                disabled:
                                                  (row.goods_type != 1 &&
                                                    row.goods_type != 2 &&
                                                    row.goods_type != 3 &&
                                                    row.goods_type != 18 &&
                                                    row.goods_type != 19 &&
                                                    row.goods_type != 10 &&
                                                    row.goods_type != 11 &&
                                                    row.goods_type != 6 &&
                                                    row.goods_type != 9 &&
                                                    row.goods_type == 17) ||
                                                  row.activity_type != 0 ||
                                                  (row.goods_type == 5 &&
                                                    row.activity_id != 0),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  _vm.utilsF(),
                                                    _vm.handleTypeChange(
                                                      $event,
                                                      row
                                                    )
                                                },
                                              },
                                              model: {
                                                value: row.goods_type,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "goods_type",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.goods_type",
                                              },
                                            },
                                            _vm._l(
                                              row.arr_goods_type,
                                              function (item_) {
                                                return _c("el-option", {
                                                  key: item_.id,
                                                  attrs: {
                                                    label: item_.name,
                                                    value: item_.id,
                                                    disabled: item_.disabled,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _c("div", [
                                        _vm._v(_vm._s(row.goods_type_text)),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2628153336
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "goods_name",
                          align: "center",
                          label: "商品名称",
                          "min-width": "260px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var $index = ref.$index
                                var row = ref.row
                                return [
                                  (row.goods_type == 1 ||
                                    row.goods_type == 2 ||
                                    row.goods_type == 10 ||
                                    row.goods_type == 11 ||
                                    row.goods_type == 3 ||
                                    row.goods_type == 6 ||
                                    row.goods_type == 9 ||
                                    row.goods_type == 17 ||
                                    (row.goods_type == 5 &&
                                      row.activity_type != 0)) &&
                                  row.index === _vm.tabClickIndex &&
                                  _vm.tabClickLabel === "商品名称"
                                    ? [
                                        _c("goods-search", {
                                          attrs: {
                                            "f-key": row.goods_name_print,
                                            kouwei: row.child,
                                            rowf: row,
                                            "cust-id": _vm.form.cust_id,
                                          },
                                          on: {
                                            "add-rows": _vm.addRows,
                                            "change-kouwei": function ($event) {
                                              return _vm.changeKouwei(
                                                $event,
                                                row
                                              )
                                            },
                                            "select-goods-all": function (
                                              $event
                                            ) {
                                              return _vm.selectGoods(
                                                $event,
                                                row,
                                                $index
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    : _c("span", [
                                        _vm._v(_vm._s(row.goods_name_print)),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          186323942
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "specs",
                          align: "center",
                          label: "规格",
                          "min-width": "150px",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "unit_code",
                          align: "center",
                          label: "条码",
                          "min-width": "110px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    " " + _vm._s(_vm.currUnitCode(row)) + " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2720019085
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "type",
                          align: "center",
                          label: "单位",
                          width: "140px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  (row.goods_type == 1 ||
                                    row.goods_type == 2 ||
                                    row.goods_type == 10 ||
                                    row.goods_type == 18 ||
                                    row.goods_type == 19 ||
                                    row.goods_type == 11 ||
                                    row.goods_type == 9 ||
                                    row.goods_type == 3 ||
                                    row.goods_type == 6 ||
                                    row.goods_type == 17 ||
                                    (row.goods_type == 5 &&
                                      row.activity_type != 0)) &&
                                  row.index === _vm.tabClickIndex &&
                                  _vm.tabClickLabel === "单位"
                                    ? [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "70px" },
                                            attrs: {
                                              placeholder: "选择单位",
                                              disabled: row.goods_name == "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.unitChange($event, row),
                                                  _vm.utilsF()
                                              },
                                            },
                                            model: {
                                              value: row.unit_id,
                                              callback: function ($$v) {
                                                _vm.$set(row, "unit_id", $$v)
                                              },
                                              expression: "row.unit_id",
                                            },
                                          },
                                          _vm._l(row.arr_unit, function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.unit_name,
                                                value: item.id,
                                              },
                                            })
                                          }),
                                          1
                                        ),
                                      ]
                                    : _c("span", [
                                        _vm._v(_vm._s(row.unit_name)),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          502413807
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "goods_price",
                          align: "center",
                          label: "单价",
                          "min-width": "110px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return [
                                  (row.goods_type == 1 ||
                                    row.goods_type == 2 ||
                                    row.goods_type == 10 ||
                                    row.goods_type == 11 ||
                                    row.goods_type == 18 ||
                                    row.goods_type == 19 ||
                                    row.goods_type == 3 ||
                                    row.goods_type == 6 ||
                                    row.goods_type == 7 ||
                                    row.goods_type == 8 ||
                                    row.goods_type == 9 ||
                                    row.goods_type == 12 ||
                                    row.goods_type == 17 ||
                                    (row.goods_type == 5 &&
                                      row.activity_type != 0)) &&
                                  row.index === _vm.tabClickIndex &&
                                  _vm.tabClickLabel === "单价"
                                    ? [
                                        _c("el-input", {
                                          class: "danjia" + $index,
                                          staticStyle: { width: "80px" },
                                          attrs: {
                                            disabled:
                                              row.goods_name == "" ||
                                              _vm.isEditPrice == 0,
                                            onkeyup:
                                              "value=value.replace(/[^\\d.]/g,'') ",
                                          },
                                          on: { input: _vm.utilsF },
                                          nativeOn: {
                                            keyup: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.danjiaD(
                                                $event,
                                                $index,
                                                "danjia"
                                              )
                                            },
                                          },
                                          model: {
                                            value: row.goods_price,
                                            callback: function ($$v) {
                                              _vm.$set(row, "goods_price", $$v)
                                            },
                                            expression: "row.goods_price",
                                          },
                                        }),
                                      ]
                                    : _c("span", [
                                        _vm._v(_vm._s(row.goods_price)),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1449453278
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "quantity",
                          align: "center",
                          label: "数量",
                          "min-width": "110px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return [
                                  (row.goods_type == 1 ||
                                    row.goods_type == 2 ||
                                    row.goods_type == 10 ||
                                    row.goods_type == 11 ||
                                    row.goods_type == 18 ||
                                    row.goods_type == 19 ||
                                    row.goods_type == 3 ||
                                    row.goods_type == 6 ||
                                    row.goods_type == 7 ||
                                    row.goods_type == 8 ||
                                    row.goods_type == 9 ||
                                    row.goods_type == 12 ||
                                    row.goods_type == 17 ||
                                    (row.goods_type == 5 &&
                                      row.activity_type != 0)) &&
                                  row.index === _vm.tabClickIndex &&
                                  _vm.tabClickLabel === "数量"
                                    ? [
                                        _c("el-input", {
                                          class: "shuliang" + $index,
                                          staticStyle: { width: "80px" },
                                          attrs: {
                                            disabled: row.goods_name == "",
                                            onkeyup:
                                              "value=value.replace(/[^\\d.]/g,'') ",
                                          },
                                          on: {
                                            input: _vm.utilsF,
                                            focus: function ($event) {
                                              return _vm.inputfocus(row)
                                            },
                                          },
                                          nativeOn: {
                                            keyup: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.danjiaD(
                                                $event,
                                                $index,
                                                "shuliang"
                                              )
                                            },
                                          },
                                          model: {
                                            value: row.quantity,
                                            callback: function ($$v) {
                                              _vm.$set(row, "quantity", $$v)
                                            },
                                            expression: "row.quantity",
                                          },
                                        }),
                                      ]
                                    : _c("span", [
                                        _vm._v(_vm._s(row.quantity)),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          98665054
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "balance_count_text",
                          align: "center",
                          label: "库存",
                          "min-width": "110px",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "goods_money",
                          align: "center",
                          label: "金额",
                          "min-width": "90px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.goods_type == 2 ||
                                  row.goods_type == 10 ||
                                  row.goods_type == 11 ||
                                  row.goods_type == 3 ||
                                  row.goods_type == 12 ||
                                  row.goods_type == 18 ||
                                  row.goods_type == 19 ||
                                  row.goods_type == 13 ||
                                  row.goods_type == 16 ||
                                  row.goods_type == 17 ||
                                  (row.goods_type == 5 &&
                                    row.activity_type != 0)
                                    ? _c("div", [_vm._v(" 0 ")])
                                    : _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              Number(
                                                row.quantity * row.goods_price
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2621656354
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "goods_production_date",
                          align: "center",
                          label: "生产日期",
                          "min-width": "140px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  (row.goods_type == 1 ||
                                    row.goods_type == 2 ||
                                    row.goods_type == 10 ||
                                    row.goods_type == 11 ||
                                    row.goods_type == 3 ||
                                    row.goods_type == 18 ||
                                    row.goods_type == 19 ||
                                    row.goods_type == 9 ||
                                    row.goods_type == 6 ||
                                    row.goods_type == 17 ||
                                    (row.goods_type == 5 &&
                                      row.activity_type != 0)) &&
                                  row.index === _vm.tabClickIndex &&
                                  _vm.tabClickLabel === "生产日期"
                                    ? [
                                        _c("el-date-picker", {
                                          staticStyle: { width: "130px" },
                                          attrs: {
                                            "value-format": "yyyy-MM-dd",
                                          },
                                          model: {
                                            value: row.goods_production_date,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                row,
                                                "goods_production_date",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "row.goods_production_date",
                                          },
                                        }),
                                      ]
                                    : [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.goods_production_date) +
                                            " "
                                        ),
                                      ],
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1111497452
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "info",
                          align: "center",
                          label: "备注",
                          "min-width": "110px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  (row.goods_type == 1 ||
                                    row.goods_type == 2 ||
                                    row.goods_type == 10 ||
                                    row.goods_type == 11 ||
                                    row.goods_type == 3 ||
                                    row.goods_type == 18 ||
                                    row.goods_type == 19 ||
                                    row.goods_type == 6 ||
                                    row.goods_type == 9 ||
                                    row.goods_type == 17 ||
                                    (row.goods_type == 5 &&
                                      row.activity_type != 0)) &&
                                  row.index === _vm.tabClickIndex &&
                                  _vm.tabClickLabel === "备注"
                                    ? [
                                        _c("el-input", {
                                          model: {
                                            value: row.info,
                                            callback: function ($$v) {
                                              _vm.$set(row, "info", $$v)
                                            },
                                            expression: "row.info",
                                          },
                                        }),
                                      ]
                                    : [_vm._v(" " + _vm._s(row.info) + " ")],
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1516461844
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "操作",
                          fixed: "right",
                          width: "160",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var $index = ref.$index
                                var row = ref.row
                                return [
                                  row.goods_type == 1 ||
                                  row.goods_type == 2 ||
                                  row.goods_type == 10 ||
                                  row.goods_type == 11 ||
                                  row.goods_type == 3 ||
                                  row.goods_type == 6 ||
                                  row.goods_type == 18 ||
                                  row.goods_type == 19 ||
                                  row.goods_type == 9 ||
                                  row.goods_type == 17 ||
                                  (row.goods_type == 5 && row.activity_id != 0)
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              nativeOn: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.copyRow(
                                                    $index,
                                                    row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" 复制 ")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              nativeOn: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.deleteRow(
                                                    $index,
                                                    row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" 删除 ")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1557749408
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      staticStyle: { "margin-top": "15px" },
                      attrs: {
                        model: _vm.form,
                        "label-width": "120px",
                        inline: true,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { label: "备注", prop: "remark" },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "订购总额", prop: "total_amount" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.total_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "total_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.total_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "退货金额",
                            prop: "refund_goods_amount",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.refund_goods_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "refund_goods_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.refund_goods_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "销售金额", prop: "sale_amount" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.sale_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "sale_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.sale_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "优惠", prop: "discount_amount" } },
                        [
                          _c("el-input", {
                            on: { input: _vm.yhInputF },
                            model: {
                              value: _vm.form.discount_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "discount_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.discount_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "优惠后", prop: "discounted_amount" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "186.25px" },
                            attrs: { min: 0, max: 1000000000, type: "number" },
                            on: { input: _vm.yhhInputF },
                            model: {
                              value: _vm.form.discounted_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "discounted_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.discounted_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "退货退款", prop: "refund_amount" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.refund_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "refund_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.refund_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "退货方式",
                            prop: "refund_type_text",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "186px" },
                              attrs: { placeholder: "退货方式" },
                              on: { change: _vm.utilsF },
                              model: {
                                value: _vm.form.refund_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "refund_type", $$v)
                                },
                                expression: "form.refund_type",
                              },
                            },
                            _vm._l(
                              _vm.allSelectList.arr_refund_type,
                              function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.is_fun
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "费用抵扣", prop: "thtk" } },
                            [
                              _c("el-input", {
                                on: { input: _vm.yshandler },
                                model: {
                                  value: _vm.form.support_amount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "support_amount",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.support_amount",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.is_fun
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "186px" },
                                  attrs: {
                                    placeholder:
                                      "请选费用" +
                                      "（合计" +
                                      _vm.funds_zong +
                                      "）",
                                    multiple: "",
                                    "collapse-tags": "",
                                  },
                                  on: { change: _vm.handlerfydk },
                                  model: {
                                    value: _vm.form.fun_ids,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "fun_ids", $$v)
                                    },
                                    expression: "form.fun_ids",
                                  },
                                },
                                _vm._l(_vm.funselect, function (item_) {
                                  return _c("el-option", {
                                    key: item_.id,
                                    attrs: {
                                      label:
                                        item_.funds_type +
                                        "(合计" +
                                        item_.left_amount +
                                        ")",
                                      value: item_.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.style == 2
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "兑奖现金:",
                                prop: "prize_amount",
                              },
                            },
                            [
                              _c("el-input", {
                                on: { input: _vm.rehandler },
                                model: {
                                  value: _vm.form.prize_amount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "prize_amount",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.prize_amount",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.style == 3
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "兑付现金:",
                                prop: "cash_amount",
                              },
                            },
                            [
                              _c("el-input", {
                                on: { input: _vm.rehandler },
                                model: {
                                  value: _vm.form.cash_amount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "cash_amount",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.cash_amount",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "应收款", prop: "receiv_money" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.receiv_money,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "receiv_money",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.receiv_money",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "使用预存款",
                            prop: "deposit_amount",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.deposit_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "deposit_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.deposit_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "还货金额", prop: "return_amount" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.return_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "return_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.return_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "60%" },
                          attrs: { label: "结算方式", prop: "account_type" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "186px" },
                              attrs: { placeholder: "结算方式" },
                              on: { change: _vm.jsfsChange },
                              model: {
                                value: _vm.form.account_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "account_type", $$v)
                                },
                                expression: "form.account_type",
                              },
                            },
                            _vm._l(
                              _vm.allSelectList.arr_account_type,
                              function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div"),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      ref: "xTable",
                      attrs: {
                        stripe: "",
                        data: _vm.xTableData,
                        "show-summary": "",
                        "summary-method": _vm.summaryMethod2,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "类型",
                          width: "auto",
                          prop: "type_id",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: "",
                                        placeholder: "类型",
                                      },
                                      model: {
                                        value: row.type_id,
                                        callback: function ($$v) {
                                          _vm.$set(row, "type_id", $$v)
                                        },
                                        expression: "row.type_id",
                                      },
                                    },
                                    _vm._l(_vm.typeSelect, function (i, idx) {
                                      return _c("el-option", {
                                        key: idx,
                                        attrs: { value: i.id, label: i.name },
                                      })
                                    }),
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1176897893
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "收款方式",
                          width: "auto",
                          prop: "id",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        clearable: "",
                                        placeholder: "收款方式",
                                      },
                                      model: {
                                        value: row.id,
                                        callback: function ($$v) {
                                          _vm.$set(row, "id", $$v)
                                        },
                                        expression: "row.id",
                                      },
                                    },
                                    _vm._l(
                                      _vm.allSelectList.arr_pay_type,
                                      function (i, idx) {
                                        return _c("el-option", {
                                          key: idx,
                                          attrs: { value: i.id, label: i.name },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1517018763
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "金额",
                          width: "auto",
                          prop: "amount",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      type: "number",
                                      placeholder: "金额",
                                    },
                                    model: {
                                      value: row.amount,
                                      callback: function ($$v) {
                                        _vm.$set(row, "amount", $$v)
                                      },
                                      expression: "row.amount",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          254211887
                        ),
                      }),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            align: "center",
                            label: "收款人",
                            width: "auto",
                            prop: "user_id",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.form.staff_name) + " ")]
                      ),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "时间",
                          width: "auto",
                          prop: "create_at",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(" " + _vm._s(row.create_at) + " "),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3881892337
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "",
                          align: "center",
                          label: "操作",
                          fixed: "right",
                          width: "130px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var $index = ref.$index
                                var row = ref.row
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleAdd($index, row)
                                        },
                                      },
                                    },
                                    [_vm._v(" 添加 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleDelete($index, row)
                                        },
                                      },
                                    },
                                    [_vm._v(" 删除 ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2613084931
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "bottom-form" },
                    [
                      _c("div", { staticClass: "tit" }, [_vm._v("相关单据：")]),
                      _vm.bottomData
                        ? [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.bottomData.bill_code,
                                    expression: "bottomData.bill_code",
                                  },
                                ],
                                staticClass: "b-item",
                              },
                              [
                                _vm._v(
                                  " 预订单: " +
                                    _vm._s(
                                      _vm.bottomData.bill_code == ""
                                        ? ""
                                        : _vm.bottomData.bill_code
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.bottomData.allot_code,
                                    expression: "bottomData.allot_code",
                                  },
                                ],
                                staticClass: "b-item",
                              },
                              [
                                _vm._v(
                                  " 调拨单: " +
                                    _vm._s(
                                      _vm.bottomData.allot_code == ""
                                        ? ""
                                        : _vm.bottomData.allot_code
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.bottomData.sale_code &&
                                      _vm.bottomData.delivery_code &&
                                      _vm.bottomData.clearing_code,
                                    expression:
                                      "\n              bottomData.sale_code &&\n              bottomData.delivery_code &&\n              bottomData.clearing_code\n            ",
                                  },
                                ],
                                staticClass: "b-item",
                              },
                              [
                                _vm._v(
                                  " 收款单: " +
                                    _vm._s(
                                      _vm.bottomData.sale_code == ""
                                        ? ""
                                        : _vm.bottomData.sale_code
                                    ) +
                                    " 、" +
                                    _vm._s(
                                      _vm.bottomData.delivery_code == ""
                                        ? ""
                                        : _vm.bottomData.delivery_code
                                    ) +
                                    " 、" +
                                    _vm._s(
                                      _vm.bottomData.clearing_code == ""
                                        ? ""
                                        : _vm.bottomData.clearing_code
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          staticClass: "checkAll",
                          on: { click: _vm.checkAll },
                        },
                        [_vm._v("查看全部单据")]
                      ),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: {
                        "margin-top": "5px",
                        "text-align": "right",
                      },
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      !_vm.isCopy
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleSave },
                            },
                            [_vm._v(" 保存 ")]
                          )
                        : _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleCopy },
                            },
                            [_vm._v("复制")]
                          ),
                      _c("el-button", { on: { click: _vm.close } }, [
                        _vm._v("取 消"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }